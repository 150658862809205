<template>
  <div>
    <spinner-loader :loading="loading" />
    <offer-form
      v-if="!loading"
      :offer="offer"
      :submit="editOffer"
    />
  </div>
</template>
<script>
import OfferForm from '@/common/components/Offers/OfferForm.vue'
import Offers from '@/common/compositions/Offers/OffersApi'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'EditOffer',
  components: { OfferForm, SpinnerLoader },
  data() {
    return {
      loading: true,
    }
  },
  setup() {
    const {
      offer, getOfferRequest, editOfferRequest, prepareFormData,
    } = Offers()
    const { successfulOperationAlert } = handleAlerts()
    return {
      offer, getOfferRequest, editOfferRequest, prepareFormData, successfulOperationAlert,
    }
  },
  created() {
    this.getOfferRequest(this.$route.params.id).then(res => {
      this.offer = {
        ...res.data.data,
        attachment: [res.data.data.attachment],
        image: [res.data.data.image],
      }
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    editOffer() {
      this.$set(this.offer, 'classification', 'community')
      this.prepareFormData()
      return this.editOfferRequest(this.$route.params.id).then(() => {
        this.successfulOperationAlert('Offer is updated successfully')
        this.$router.back()
      })
    },
  },
}
</script>
<style lang="">

</style>
